





import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import TeamPageAlertList from './TeamPageAlertList.vue'
import TeamPageAlertEditor from './TeamPageAlertEditor.vue'
import * as teamPageAlertStore from '@/store/teamPageAlerts'

export default defineComponent({
  name: 'TeamPageAlerts',
  components: {
    TeamPageAlertList,
    TeamPageAlertEditor,
  },
  setup() {
    const viewMode = computed(() => store.getters.teamPageAlerts.viewMode)

    const componentName = ref('TeamPageAlertList')

    watch(
      () => viewMode.value,
      () => {
        if (isInEditOrCreateMode()) {
          componentName.value = 'TeamPageAlertEditor'
        } else {
          componentName.value = 'TeamPageAlertList'
        }
      },
      { immediate: true }
    )

    function isInEditOrCreateMode(): boolean {
      return (
        viewMode.value === teamPageAlertStore.viewModes.edit ||
        viewMode.value === teamPageAlertStore.viewModes.create
      )
    }

    return {
      componentName,
    }
  },
})
