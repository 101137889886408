


































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import * as divisionStore from '@/store/divisions'
import * as programTypesStore from '@/store/programTypes'
import * as teamStore from '@/store/teams'
import * as teamPageAlertStore from '@/store/teamPageAlerts'
import { viewModes } from '@/store/teamPageAlerts'
import { Action, Getter, Mutation } from 'vuex-class'
import { cloneDeep } from 'lodash'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { League } from '@/GeneratedTypes/League'
import { LeagueProgram } from 'src/GeneratedTypes/LeagueProgram'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import SelectInput from '@/elements/SelectInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import DateInput from '@/elements/DateInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import { ValidationObserver } from 'vee-validate'
import { programTypeDescription } from '@/lib/common/ProgramTypes'
import { getEmptyTeamPageAlertInfo } from '@/lib/support/models/TeamPageAlertInfo/data'
import { convertToOldFashionDateTime } from '@/lib/support/utilities/time/time'

interface ProgramSelectInputOption {
  optionText: string
  optionValue: string | null
}

@Component({
  components: {
    SelectInput,
    DateInput,
    HtmlEditor,
    VeeValidateForm,
    ValidationObserver,
  },
})
export default class TeamPageAlertEditor extends Vue {
  @Action(teamPageAlertStore.actionNames.endEdit, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly endEdit!: () => void

  @Action(teamPageAlertStore.actionNames.endCreating, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly endCreating!: () => void

  @Action(teamPageAlertStore.actionNames.save, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly save!: () => TeamPageAlertInfo

  @Getter(teamPageAlertStore.getterNames.viewMode, {
    namespace: teamPageAlertStore.namespace,
  })
  private viewMode!: viewModes

  @Getter(teamPageAlertStore.getterNames.editorItem, {
    namespace: teamPageAlertStore.namespace,
  })
  private inEditItem!: TeamPageAlertInfo

  @Mutation(teamPageAlertStore.mutationNames.setInEditItem, {
    namespace: teamPageAlertStore.namespace,
  })
  private setInEditItem!: ({ item }: { item: TeamPageAlertInfo }) => void

  @Getter(teamPageAlertStore.getterNames.currentLeague, {
    namespace: teamPageAlertStore.namespace,
  })
  private currentLeague!: League

  @Getter(programTypesStore.getterNames.allItems, { namespace: programTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(divisionStore.getterNames.allItems, { namespace: divisionStore.namespace })
  readonly allDivisions!: (s: string) => LeagueDivisionInfo[]

  @Getter(teamStore.getterNames.teams, { namespace: teamStore.namespace })
  readonly allTeams!: DivisionTeamInfo[]

  private programs: LeagueProgram[] | null = [] as LeagueProgram[]
  private localInEditItem: TeamPageAlertInfo = getEmptyTeamPageAlertInfo()
  private programDivisions: LeagueDivisionInfo[] | null = [] as LeagueDivisionInfo[]
  private divisionTeams: DivisionTeamInfo[] | null = [] as DivisionTeamInfo[]
  private selectedProgram = 'NONE'
  private selectedDivisionID = 0

  private get programBlankItemText() {
    if (this.programs && this.programs.length > 0) {
      return `Both ${programTypeDescription(this.programs[0].typeProgramID)} and Cheer`
    } else {
      return '?All?' //should never be here
    }
  }

  private get programOptions(): ProgramSelectInputOption[] {
    if (this.programs) {
      return (
        this.programs.map((p) => {
          return {
            optionText: programTypeDescription(p.typeProgramID) ?? '',
            optionValue: p.typeProgramID,
          } as ProgramSelectInputOption
        }) ?? []
      )
    } else {
      return []
    }
  }

  @Watch('selectedProgram')
  private onProgramChanged() {
    if (this.selectedProgram != 'NONE') {
      this.programDivisions = this.allDivisions(this.currentLeague.upwardLeagueID ?? '').filter(
        (x) => x.typeProgramID == this.selectedProgram
      )
    } else {
      this.programDivisions = []
    }
    if (this.localInEditItem.typeProgramID != this.selectedProgram) {
      this.selectedDivisionID = 0
      this.localInEditItem.typeProgramID = this.selectedProgram
    }
  }

  @Watch('selectedDivisionID')
  private onDivisionChanged() {
    if (this.selectedDivisionID > 0) {
      this.divisionTeams = this.allTeams.filter(
        (x) => x.typeProgramID == this.selectedProgram && x.divisionID == this.selectedDivisionID
      )
    } else {
      this.divisionTeams = []
    }

    if (this.localInEditItem.divisionID != this.selectedDivisionID) {
      this.localInEditItem.upwardTeamID = ''
      this.localInEditItem.divisionID = this.selectedDivisionID
    }
  }

  @Watch('viewMode', { immediate: true })
  private onViewModeChanged() {
    this.updateView()
  }

  public mounted() {
    this.localInEditItem = cloneDeep(this.inEditItem)
    this.programs = this.currentLeague.programs ?? []
    if (this.isInCreateMode()) {
      this.selectedProgram = this.programs.length > 1 ? 'NONE' : this.programs[0].typeProgramID
    } else {
      this.selectedProgram = this.localInEditItem.typeProgramID ?? 'NONE'
      this.selectedDivisionID = this.localInEditItem.divisionID
    }
    this.updateView()
  }

  private isInEditMode() {
    return this.viewMode === viewModes.edit
  }

  private isInCreateMode() {
    return this.viewMode === viewModes.create
  }

  private async saveItem() {
    // make sure form is valid
    const formIsValid = await (this.$refs.vvForm as Vue & { checkValidity: () => boolean }).checkValidity()
    if (formIsValid) {
      const alert = this.dealWithLocalTime()
      this.setInEditItem({ item: alert })
      await this.save()
      if (this.viewMode === viewModes.create) {
        this.endCreating()
      } else {
        this.endEdit()
      }
    }
  }

  private cancelEdit() {
    if (this.viewMode === viewModes.edit) {
      this.endEdit()
    }

    if (this.viewMode === viewModes.create) {
      this.endCreating()
    }
  }

  private dealWithLocalTime() {
    // deal with local vs UTC time
    const newAlert = cloneDeep(this.localInEditItem)
    //@ts-ignore
    newAlert.startDate = convertToOldFashionDateTime(this.localInEditItem.startDate, '00', '00', '00')
    //@ts-ignore
    newAlert.expireDate = convertToOldFashionDateTime(this.localInEditItem.expireDate, '23', '59', '59')

    return newAlert
  }

  private updateView() {
    return //place holder just in case
  }
}
