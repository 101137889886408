

































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import * as divisionStore from '@/store/divisions'
import * as programTypesStore from '@/store/programTypes'
import * as teamStore from '@/store/teams'
import * as teamPageAlertStore from '@/store/teamPageAlerts'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

import TextInput from '@/elements/TextInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import Loading from '@/elements/Loading.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'

import DataTable from '@/elements/DataTable/DataTable.vue'
import { DataTableHeader } from 'vuetify/types'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import dayjs from 'dayjs'
import { programTypeDescription } from '@/lib/common/ProgramTypes'

import { viewModes } from '@/store/teamPageAlerts'

interface AlertDataTableElement {
  alertID: number
  message: string | null
  intendedFor: string
  visibility: string
  isActive: boolean
  isPast: boolean
  isFuture: boolean
  actions: string
}

@Component({
  components: {
    TextInput,
    YesNoInput,
    ConfirmDeleteModal,
    Loading,
    DataTable,
    EditBtn,
    DeleteBtn,
  },
})
export default class TeamPageAlertList extends Vue {
  public deleteItem(alert: AlertDataTableElement) {
    if (alert) {
      this.setCurrentAlert({ id: alert.alertID })
      this.showConfirmDelete = true
    }
  }

  public async editItem(alert: AlertDataTableElement) {
    if (alert) {
      this.setCurrentAlert({ id: alert.alertID })
      await this.beginEdit()
    }
  }

  @Action(teamPageAlertStore.actionNames.load, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly retrieveAlerts!: ({ id }: { id: string }) => TeamPageAlertInfo[] | null

  @Action(teamPageAlertStore.actionNames.delete, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly deleteAlert!: () => boolean

  @Getter(teamPageAlertStore.getterNames.currentItem, {
    namespace: teamPageAlertStore.namespace,
  })
  private currentAlert!: TeamPageAlertInfo

  @Getter(teamPageAlertStore.getterNames.items, {
    namespace: teamPageAlertStore.namespace,
  })
  private alerts!: TeamPageAlertInfo[]

  @Action(teamPageAlertStore.actionNames.set, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly setCurrentAlert!: ({ id }: { id: number }) => TeamPageAlertInfo

  @Action(teamPageAlertStore.actionNames.beginEdit, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly beginEdit!: () => void

  @Action(teamPageAlertStore.actionNames.beginCreating, {
    namespace: teamPageAlertStore.namespace,
  })
  private readonly beginCreating!: () => void

  @Getter(teamPageAlertStore.getterNames.viewMode, {
    namespace: teamPageAlertStore.namespace,
  })
  private viewMode!: viewModes

  @Action(programTypesStore.actionNames.fetchAll, { namespace: programTypesStore.namespace })
  private fetchProgramTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Getter(programTypesStore.getterNames.allItems, { namespace: programTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Action(divisionStore.actionNames.fetchAll, { namespace: divisionStore.namespace })
  private fetchAllDivisions!: ({ upwardLeagueId }: { upwardLeagueId: string }) => Promise<boolean>

  @Action(teamStore.actionNames.fetchTeams, { namespace: teamStore.namespace })
  private fetchAllTeams!: ({ upwId }: { upwId: string }) => Promise<boolean>

  headers: DataTableHeader[] = [
    { text: 'Message', value: 'message' },
    { text: 'Intended For', value: 'intendedFor' },
    { text: 'Visibility', value: 'visibilityInfo' },
    { text: '', value: 'actions' },
  ]

  private showConfirmDelete = false
  private selectedAlertId = 0
  private isLoading = true
  private isDeleting = false

  public async mounted() {
    await this.fetchAllDivisions({ upwardLeagueId: this.$route.params.id })
    await this.fetchAllTeams({ upwId: this.$route.params.id })
    await this.fetchProgramTypes({ force: false })
    await this.retrieveAlerts({ id: this.$route.params.id })
    this.isLoading = false
  }

  private getAlertBackground(a: AlertDataTableElement) {
    return a.isActive ? 'bg-active' : a.isPast ? 'bg-past' : 'bg-future'
  }

  rowSelected(x: DataTableSelection<AlertDataTableElement>) {
    this.selectedAlertId = x.item.alertID
    this.setCurrentAlert({ id: this.selectedAlertId })
  }

  rowDoubleClick() {
    this.beginEdit()
  }

  private onBeginEdit() {
    this.beginEdit()
  }

  private async create() {
    await this.beginCreating()
  }

  private edit() {
    this.beginEdit()
  }

  async confirmDeleteAlert() {
    try {
      this.isDeleting = true
      await this.deleteAlert()
      await this.retrieveAlerts({ id: this.$route.params.id })
    } finally {
      this.showConfirmDelete = false
      this.isDeleting = false
    }
  }

  private cancelDeleteAlert() {
    this.showConfirmDelete = false
  }

  private formattedDate(d: Date | null) {
    return dayjs(d ?? '').format('MMM DD')
  }

  private intendedFor(a: TeamPageAlertInfo) {
    return a.teamName
      ? `Team: ${a.teamName}`
      : a.divisionName
      ? `Division: ${a.divisionName}`
      : `${programTypeDescription(a.typeProgramID) ?? 'Everyone'}`
  }

  private visibilityInfo(a: TeamPageAlertInfo) {
    return this.isActive(a)
      ? `Now showing until ${this.formattedDate(a.expireDate)}`
      : this.isPast(a)
      ? `Expired on ${this.formattedDate(a.expireDate)}`
      : `Will show on ${this.formattedDate(a.startDate)}`
  }

  private isActive(a: TeamPageAlertInfo) {
    return dayjs().isAfter(a.startDate ?? '') && dayjs().isBefore(a.expireDate ?? '')
  }

  private isPast(a: TeamPageAlertInfo) {
    return dayjs().isAfter(a.expireDate ?? '')
  }
  private isFuture(a: TeamPageAlertInfo) {
    return dayjs().isBefore(a.startDate ?? '')
  }

  get getAlerts() {
    if (this.alerts && this.alerts.length > 0) {
      return this.alerts.map((a) => {
        return {
          alertID: a.id,
          message: a.message?.replace(/<p>/g, '').replace(/<\/p>/g, '') ?? '',
          intendedFor: this.intendedFor(a),
          visibilityInfo: this.visibilityInfo(a),
          isActive: this.isActive(a),
          isPast: this.isPast(a),
          isFuture: this.isFuture(a),
          actions: '',
        }
      })
    }

    return []
  }
}
